import React from "react";
import "./Mentors_StartUpsYouFollowContent.css";
import Infocard from "./Mentors_StartUpsYouFollowInfocard";
function Content() {
  return (
    <div className="mentorsstartupsyoufollowContent">
      <Infocard />
      <Infocard />
      <Infocard />
      <Infocard />
      <Infocard />
      <Infocard />
      <Infocard />
      <Infocard />
      <Infocard />
      <Infocard />
      <Infocard />
      <Infocard />
      <div> </div>
    </div>
  );
}

export default Content;
