import React from "react";
import "./ListOfStartupsContent.css";
import Infocard from "./ListOfStartupsInfocard";
function Content() {
  return (
    <div className="listofstartupsContent">
      <Infocard />
      <Infocard />
      <Infocard />
      <Infocard />
      <Infocard />
      <Infocard />
      <Infocard />
      <Infocard />
      <Infocard />
      <Infocard />
      <Infocard />
      <Infocard />
      <div> </div>
    </div>
  );
}

export default Content;
